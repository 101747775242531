
import { Options, Vue } from 'vue-class-component'
import clubEvents from '@/components/Memberportal/ClubEvents/index.vue'

@Options({
  components: {
    clubEvents
  }
})

export default class ClubEvents extends Vue {}
