import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import MembersDataService from '@/services/MembersDataService'
import { Events } from '@/services/EventsDataService'
import SysEvent from '@/types/SysEvent'
import SysMember from '@/types/SysMember'
import BaseFunctions, { defaultClubManagerGroupId } from '@/components/Utility/Base'
import CommonFunctions, { defaultInvitationURL } from '@/components/Utility/Common'
import MediasDataService from '@/services/MediasDataService'
import AuthService from '@/services/AuthService'

type clubEventDataReturn = { id: number; title: string; description: string; location: string; price: string; start: string; end: string; registrationDeadline: string; invitation: any[], extraContactTelephone: string; extraContactEmail: string; }
type dataReturnType = { clubEventData: clubEventDataReturn, validation: boolean; uploadInvitation: any[] ; }

export default class clubEvents extends Vue {
  private clubManagerId = this.parseURL()
  private clubId = 0
  private todaysDate = new Date().toISOString().split('T')[0]
  private clubEvents: SysEvent[] = []
  private eventStartParameter = `&event_start_gte=${this.todaysDate}`
  private currentListSortingOrder = 'event_start:asc'
  filterByTimeValue = 'Kommende arrangementer'
  filterByTimeOptions: string[] = ['Alle arrangementer', 'Kommende arrangementer', 'Afholdte arrangementer']
  clubEventData: clubEventDataReturn = { id: 0, title: '', description: '', location: '', price: '', start: '', end: '', registrationDeadline: '', invitation: [], extraContactEmail: '', extraContactTelephone: '' }
  clubEventsModal = false
  defaultURL = defaultInvitationURL
  // private uploadInvitation: any[] = []
  private uploadInvitation: any = []
  private oldInvitation: any[] = []
  private createNewEvent = false
  private validation = false

  readonly name : string = 'clubEvents'
  $Message: any
  data (): dataReturnType {
    return {
      clubEventData: this.clubEventData,
      validation: this.validation,
      uploadInvitation: this.uploadInvitation
    }
  }

  @Watch('uploadInvitation')
  onNewInvitation () : void {
    console.log(this.uploadInvitation)

    // if (this.uploadInvitation !== undefined && this.uploadInvitation.length > 0) {
    if (this.uploadInvitation !== undefined && JSON.stringify(<File> this.uploadInvitation.size) !== null && Number((<File> this.uploadInvitation).size) > 0) {
      console.log('[uploadInvitation()] uploadInvitation.size = ' + Number((<File> this.uploadInvitation).size).toString())
      // const fileToUpload = <File> this.uploadInvitation[0]
      const fileToUpload = <File> this.uploadInvitation
      const fileExtension = (fileToUpload === undefined || fileToUpload === null ? '' : fileToUpload.name.split('?')[0].split('.').pop())
      const newFileName = (this.clubEventData.title === '' ? 'clubEvent-' + this.clubId.toString() : this.clubEventData.title.trim()) + '.' + fileExtension
      const fd: FormData = new FormData()

      // console.log('[uploadInvitation()] fileExtension = ' + fileExtension)
      // console.log('[uploadInvitation()] newFileName = ' + newFileName)
      // fd.append('files', this.uploadInvitation[0], newFileName)
      fd.append('files', this.uploadInvitation, newFileName)

      MediasDataService.upload(fd)
        .then((response) => {
          console.log(response.data)

          if (this.clubEventData.invitation.length > 0) {
            this.oldInvitation = this.clubEventData.invitation
          }

          this.clubEventData.invitation = response.data
          console.log('[uploadInvitation()] response.data = ' + JSON.stringify(response.data))

          if (this.clubEventData.id !== 0 && !this.createNewEvent) {
            const updateClubEvent = {
              event_invitation: response.data
            }
            Events.EventsDataService.update(this.clubEventData.id.toString(), updateClubEvent)
              .then((response) => {
                console.log(response.data)
                this.editClubEvent(this.clubEventData.id)
              })
              .catch((err) => {
                console.error(err)
              })
          }

          if (this.oldInvitation.length > 0) {
            MediasDataService.delete(this.oldInvitation[0].id)
              .then((response) => {
                console.log(response.data)
                this.oldInvitation = []
              })
              .catch((err) => {
                console.error(err)
              })
          }

          this.uploadInvitation = []
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  @Watch('clubEventsModal')
  onModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      if (this.oldInvitation.length > 0) {
        MediasDataService.delete(this.oldInvitation[0].id)
          .then((response) => {
            console.log('Deleting old Invitation: ' + response.statusText)
          })
          .catch((err) => {
            console.error(err)
          })
      }
      this.clubEventData = { id: 0, title: '', description: '', location: '', price: '', start: '', end: '', registrationDeadline: '', invitation: [], extraContactEmail: '', extraContactTelephone: '' }
      this.createNewEvent = false
    }
  }

  @Watch('filterByTimeValue')
  onFilterByTimeValueChange (status: string) : void {
    if (status === 'Alle arrangementer') {
      this.eventStartParameter = ''
    }
    if (status === 'Kommende arrangementer') {
      this.eventStartParameter = `&event_start_gte=${this.todaysDate}`
    }
    if (status === 'Afholdte arrangementer') {
      this.eventStartParameter = `&event_start_lt=${this.todaysDate}`
    }
    this.retrieveClubEvents()
  }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public newEvent () : void {
    this.createNewEvent = true
    this.clubEventsModal = true
  }

  public createNewClubEvent () {
    // console.log('[createNewClubEvent()] DONE :')
    // console.log(this.clubEventData.invitation)
    if (!this.validation) {
      return this.$Message.warning({ text: 'Et felt eller flere felter er ikke udfyldt korrekt' })
    }

    // console.log('[createNewClubEvent()] ' + JSON.stringify([this.clubEventData.invitation]))

    // if (this.clubEventData.invitation.length === 0) {
    //   return this.$Message.warning({ text: 'Fejl: Ingen fil-upload' })
    // }

    const createNewEvent = {
      event_status: true,
      event_titel: this.clubEventData.title,
      event_beskrivelse: this.clubEventData.description,
      event_pris: (this.clubEventData.price === '' ? 0 : Number(this.clubEventData.price)),
      event_start: (new Date(this.clubEventData.start)).toISOString(),
      event_slut: (new Date(this.clubEventData.end)).toISOString(),
      event_sted: this.clubEventData.location,
      event_invitation: this.clubEventData.invitation,
      user_id: this.clubManagerId,
      klubber_id: this.clubId,
      event_type_id: 6,
      event_tilmelding_mail: (this.clubEventData.extraContactEmail === '' ? null : this.clubEventData.extraContactEmail),
      event_tilmelding_telefon: (this.clubEventData.extraContactTelephone === '' ? null : this.clubEventData.extraContactTelephone),
      event_sidstetilmelding: (this.clubEventData.registrationDeadline === '' ? (new Date(this.clubEventData.start.substring(0, 10))).toISOString() : this.clubEventData.registrationDeadline)
    }

    Events.EventsDataService.create(createNewEvent)
      .then((response) => {
        console.log('New Club Event Created: ' + response.statusText)
        this.clubEventsModal = false
        this.createNewEvent = false
        this.retrieveClubEvents()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public updateEvent (eventId: number) {
    if (!this.validation) {
      return this.$Message.warning({ text: 'Et eller flere felter er ikke udfyldt korrekt' })
    }

    const updateEvent = {
      event_titel: this.clubEventData.title,
      event_beskrivelse: this.clubEventData.description,
      event_pris: (this.clubEventData.price === '' ? 0 : Number(this.clubEventData.price)),
      event_start: (new Date(this.clubEventData.start)).toISOString(),
      event_slut: (new Date(this.clubEventData.end)).toISOString(),
      event_sidstetilmelding: this.clubEventData.registrationDeadline,
      event_sted: this.clubEventData.location,
      event_invitation: this.clubEventData.invitation,
      event_tilmelding_mail: (this.clubEventData.extraContactEmail === '' ? null : this.clubEventData.extraContactEmail),
      event_kontakt_telefon: (this.clubEventData.extraContactTelephone === '' ? null : this.clubEventData.extraContactTelephone)
    }

    Events.EventsDataService.update(eventId.toString(), updateEvent)
      .then((response) => {
        console.log(response.data)
        this.retrieveClubEvents()
        this.clubEventsModal = false
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public editClubEvent (id: number) : void {
    this.createNewEvent = false
    let tempEvent = {} as SysEvent
    this.clubEventsModal = true
    Events.EventsDataService.get(id.toString())
      .then((response) => {
        tempEvent = response.data

        this.clubEventData = {
          id: Number(tempEvent.id),
          title: tempEvent.event_titel,
          description: tempEvent.event_beskrivelse,
          location: tempEvent.event_sted,
          price: (tempEvent.event_pris === 0 ? '' : tempEvent.event_pris.toString()),
          start: this.locConvertFromUTCtoLocalDateTime(tempEvent.event_start).substring(0, 16),
          end: this.locConvertFromUTCtoLocalDateTime(tempEvent.event_slut).substring(0, 16),
          // start: new Date(tempEvent.event_start).toISOString().substring(0, 19),
          // end: new Date(tempEvent.event_slut).toISOString().substring(0, 19),
          registrationDeadline: (tempEvent.event_sidstetilmelding === null ? tempEvent.event_start.substring(0, 10) : tempEvent.event_sidstetilmelding),
          invitation: tempEvent.event_invitation,
          extraContactEmail: tempEvent.event_tilmelding_mail,
          extraContactTelephone: tempEvent.event_kontakt_telefon
        }
        console.log(this.clubEventData)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    // console.log('[locConvertFromUTCtoLocalDateTime()] inputDatetimeString = ' + inputDatetimeString)
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 5) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public danishDateString (date: string) : string {
    return CommonFunctions.toDanishDateString(date)
  }

  public toDanishDateWWeekDay (dateString: string) : string {
    return CommonFunctions.toDanishDateStringWWeekDate(dateString)
  }

  public getClockFromDateString (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public beingHeld (startDate: string, endDate: string) : string {
    let retVal = ''
    // const start = new Date(startDate).toISOString().split('T')[0]
    // const end = new Date(endDate).toISOString().split('T')[0]

    // if (start === end) {
    //   retVal = CommonFunctions.toDanishDateStringWWeekDate(start)
    // } else {
    //   retVal = CommonFunctions.toDanishDateStringWWeekDate(start) + ' - ' + CommonFunctions.toDanishDateStringWWeekDate(end)
    // }

    if (startDate !== null && startDate.length >= 1 && endDate !== null && endDate.length >= 1) {
      if ((new Date(startDate).toISOString().split('T')[0]) === (new Date(endDate).toISOString().split('T')[0])) {
        retVal = this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(startDate))
      } else {
        retVal = this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(startDate)) + ' - ' + this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(endDate))
      }
    }

    return retVal
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const managerId = route.params.userSlug.toString().split('-')[0]

    if (Number(managerId) !== undefined) {
      retVal = Number(managerId)
    }
    return retVal
  }

  public retrieveClubEvents () {
    let manager: SysMember[] = []

    // Changed to use the userId from localStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        manager = response.data
        if (this.clubManagerId !== Number(manager[0].id)) {
          AuthService.logout()
          CommonFunctions.redirectLogin()
          return
        }

        if (manager[0].user_id.usrgroup !== defaultClubManagerGroupId) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          AuthService.logout()
          return this.$router.push({ name: 'Login', path: 'medlemsports/login' })
        }
        this.clubId = Number(manager[0].klubber_id.id)
        Events.EventsDataService.getAll(this.currentListSortingOrder, null, `event_status=true&klubber_id.id=${this.clubId}${this.eventStartParameter}`)
          .then((response) => {
            this.clubEvents = response.data
            console.log(this.clubEvents)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveDate () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = new Date(response.data.split('T')[0]).toISOString().split('T')[0]
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveClubEvents()
      this.retrieveDate()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
